import request from '../request'

export function fetchList(params) {
    return request({
        url: '/office-service/supervise/list',
        params,
    })
}

export function add(data) {
    return request({
        url: '/office-service/supervise/add',
        method: 'post',
        data,
    })
}

export function edit(data) {
    return request({
        url: '/office-service/supervise/update',
        method: 'post',
        data,
    })
}

export function remove(data) {
    return request({
        url: '/office-service/supervise/delete',
        method: 'post',
        data,
    })
}

export function fetchDetail(params) {
    return request({
        url: `/office-service/supervise/query/${params.id}`,
    })
}