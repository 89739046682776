import request from '../../request'

export function fetchList(params) {
    return request({
        url: '/common-service/staffculture/list',
        params,
    })
}

export function add(data) {
    return request({
        url: '/common-service/staffculture/add',
        data,
        method: "post",
    })
}

export function update(data) {
    return request({
        url: '/common-service/staffculture/update',
        data,
        method: "post",
    })
}

export function fetchDetail(params) {
    return request({
        url: '/common-service/staffculture/query/' + params.id,
    })
}

export function remove(data) {
    return request({
        url: '/common-service/staffculture/del',
        data,
        method: "post",
    })
}