<template>
  <Card title="起草中心" url="/approval/center" name="更多">
    <div class="list">
      <router-link v-for="item in filteredList" :key="item.path" class="left item" :to="item.path">
        <a-icon type="file-text" theme="filled" :style="{
          color: item.color ? item.color : '#1890ff',
        }" />
        <span>
          {{ item.title }}
        </span>
      </router-link>
    </div>
  </Card>
</template>



<script>
import Card from "./card.vue";
export default {
  components: {
    Card,
  },
  data() {
    return {
      list: [
        {
          color: "#52C41A",
          title: "合同金额变更申请",
          path: "/produce/contract-adjust/add",
        },
        {
          color: "#52C41A",
          title: "收入合同审批",
          path: "/produce/contractRegister/review/add",
        },
        {
          color: "#52C41A",
          title: "外部采购类合同审批",
          path: "/produce/contractRegister/subpackage/add",
        },
        {
          color: "#52C41A",

          title: "框架类合同审批",
          path: "/produce/contractRegister/framework/add",
        },

        {
          title: "酒水申领审批表",
          path: "/oa/material/apply",
        },
        {
          title: "会议室申请审批表",
          path: "/oa/meeting/add",
        },

        {
          title: "任务督办单",
          path: "/oa/supervise/add",
        },

        {
          title: "档案移交审批表",
          path: "/oa/archive/query/transfer",
        },

        {
          title: "档案出借(检索)",
          path: "/oa/archive/query",
        },
        {
          title: "开票换票退票审批表",
          path: "/finance/invoice/apply",
        },
      ],
    };
  },
  computed: {
    filteredList() {
      return this.list.filter(
        (item) => (item.path && this.$getPermission(item.path)) || !item.path
      );
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  padding: 4px 0 4px 0;

  .item {
    color: #666;

    span {
      margin-left: 4px;
    }
  }
}
</style>