<template>
  <div class="left welcome">
    <span> {{ greeting }}， </span>
    <span style="color: #1890ff"> {{ userName }}， </span>
    <span>
      {{ message }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    userName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      greeting: "",
      message: "",
    };
  },
  mounted() {
    const now = new Date();
    const hour = now.getHours();
    if (hour >= 6 && hour < 10) {
      this.greeting = "早安";
      this.message = "记得吃早饭哦~";
    } else if (hour >= 10 && hour < 11) {
      this.greeting = "上午好";
      this.message = "午餐吃什么好呢？";
    } else if (hour >= 11 && hour < 13) {
      this.greeting = "中午好";
      this.message = "记得按时吃饭哦！";
    } else if (hour >= 13 && hour < 17) {
      this.greeting = "下午好";
      this.message = `来杯下午茶吧！`;
    } else if (hour >= 17 && hour < 19) {
      this.greeting = "傍晚好";
      this.message = `该吃晚饭啦！！`;
    } else if (hour >= 19 && hour < 24) {
      this.greeting = "晚上好";
      this.message = "不能太累哦！";
    } else if (hour >= 0 && hour < 6) {
      this.greeting = "夜深了";
      this.message = "早点休息吧！";
    }
  },
};
</script>


<style lang="less" scoped>
.welcome {
  margin-bottom: 6px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 18px;
  font-weight: bold;
}
</style>