<template>
    <Card title="外部网站" url="/knowledge/lib/out" name="更多">
        <a-row>
            <a-col :span="6" v-for="item in list" :key="item.id">
                <a class="item" target="_blank" :href="item.path">
                    <img :src="item.imagePath" alt="系统图标" />
                    <div>{{ item.title }}</div>
                </a>
            </a-col>
        </a-row>
    </Card>
</template>
  
<script>
import Card from "./card.vue";
export default {
    components: {
        Card,
    },
    data() {
        return {
            list: [

                {
                    imagePath: require('../assets/22.png'),
                    title: "协同设计",
                    path: "http://codesign.bewg.net.cn:8022/portal/portal.aspx",
                },
                {
                    // icon: require('../assets/11.jpeg'),
                    imagePath: require('../assets/33.png'),
                    title: "三纲出图",
                    path: "http://10.23.23.118",
                },
                {
                    imagePath: require('../assets/bk-water.jpeg'),
                    title: "集团门户",
                    path: 'https://portal.bewg.net.cn',
                },


                {
                    imagePath: require('../assets/oa.png'),
                    title: "集团OA",
                    path: 'http://office.bewg.net.cn/seeyon/main.do?method=main',
                },
                {
                    imagePath: require('../assets/email.png'),
                    title: "企业邮箱",
                    path: 'https://qiye.aliyun.com/alimail/',
                },

                {
                    imagePath: require('../assets/bill.png'),
                    title: "网上报销",
                    path: 'http://fstmp.bewg.net.cn:9080/portal/pt/home/index?ssoKey=8ef20563-a46a-424b-9124-8627bdcdd467',
                },
                {
                    imagePath: require('../assets/scrm.png'),
                    title: "SCRM",
                    path: 'https://scrm.njszy.com/',
                },
                {
                    imagePath: require('../assets/partyManage.png'),
                    title: "智慧党建",
                    path: 'https://portal.njszy.com/party',
                }
            ],
        };
    },

};
</script>
  
<style lang="less" scoped>
.card {
    background-color: #fff;
    padding-bottom: 0;
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 4px;
    color: #333;
    font-size: 12px;
    cursor: pointer;

    &:hover {
        background-color: #f9f9f9;
    }

    img {
        height: 38px;
        width: 38px;
        object-fit: cover;
        margin-bottom: 6px;
        border-radius: 6px;
    }
}
</style>