<template>
  <Card title="审批协同" name="审批中心" url="/approval/task">
    <div class="list">
      <div
        class="item"
        style="background-color: #1890ff"
        @click="$router.push('/approval/task')"
      >
        <div class="label">待办事项</div>
        <div>
          <span class="value">{{ taskLength }}</span>
          <span>项</span>
        </div>
        <div class="tag">ToDo</div>
      </div>
      <div
        class="item"
        style="background-color: #51bbc8"
        @click="$router.push('/approval/task?taskNotify=notify_task')"
      >
        <div class="label">待阅消息</div>
        <div>
          <span class="value">{{ notifyTaskLength }}</span>
          <span>条</span>
        </div>
        <div class="tag">Message</div>
      </div>
      <div
        class="item"
        style="background-color: #ee7759"
        @click="$router.push('/oa/supervise')"
      >
        <div class="label">督办事项</div>
        <div>
          <span class="value">{{ superviseLength }}</span>
          <span>项</span>
        </div>
        <div class="tag">Task</div>
      </div>
      <div
        class="item"
        style="background-color: #f4b041"
        @click="$router.push('/message-center')"
      >
        <div class="label">消息提醒</div>
        <div>
          <span class="value">{{ notReadTotal }}</span>
          <span>条</span>
        </div>
        <div class="tag">Remind</div>
      </div>
    </div>
  </Card>
</template>

<script>
import { mapState } from "vuex";
import Card from "./card.vue";

import { fetchTask } from "@/api/approval";
import { fetchList as fetchSuperviseList } from "@/api/supervise";

export default {
  components: {
    Card,
  },

  data() {
    return {
      taskLength: 0,
      notifyTaskLength: 0,
      superviseLength: 0,
    };
  },

  computed: {
    ...mapState("message", ["notReadTotal"]),
  },

  mounted() {
    fetchTask({
      pageNum: 1,
      pageSize: 10,
      status: "RUNNING",
    }).then((res) => {
      if (res.totalSize) {
        this.taskLength = res.totalSize;
      }
    });

    fetchTask({
      pageNum: 1,
      pageSize: 10,
      taskNotify: "notify_task",
      status: "RUNNING",
    }).then((res) => {
      if (res.totalSize) {
        this.notifyTaskLength = res.totalSize;
      }
    });

    fetchSuperviseList({
      pageNum: 1,
      pageSize: 10,
      status: "processing",
    }).then((res) => {
      if (res.totalSize) {
        this.superviseLength = res.totalSize;
      }
    });
  },
};
</script>

<style lang="less" scoped>
.list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;

  .item {
    border-radius: 8px;
    padding: 8px;
    color: #fff;
    position: relative;
    cursor: pointer;
    .label {
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 12px;
    }

    .value {
      font-size: 2em;
      padding-right: 8px;
    }

    .tag {
      position: absolute;
      top: 0;
      right: 4px;
      font-size: 24px;
      color: rgba(255, 255, 255, 0.2);
    }
  }
}
</style>