<template>
  <Card title="公告" url="/oa/notice">
    <div v-if="list.length > 0">
      <div v-for="item in list" :key="item.id">
        <router-link class="item" :to="`/oa/notice/detail?id=${item.id}`">
          <div class="name">
            {{ item.title }}
          </div>
          <div class="time">
            {{ $getDateString(item.publishAt) }}
          </div>
        </router-link>
      </div>
    </div>
    <div v-else style="padding-top: 12px">
      <a-empty />
    </div>
  </Card>
</template>



<script>
import Card from "./card.vue";
import { fetchList } from "@/api/oa/notice";

export default {
  components: {
    Card,
  },
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    fetchList({
      pageNum: 1,
      pageSize: 8,
    }).then((res) => {
      if (res && Array.isArray(res.list)) {
        this.list = Object.freeze(res.list);
      }
    });
  },
};
</script>

<style lang="less" scoped>
.item {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;

  padding-left: 12px;
  position: relative;

  &::before {
    position: absolute;

    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.85);
  }

  &:hover {
    background-color: #f9f9f9;
  }
  .name {
    width: 18vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .time {
    font-family: sans-serif;
    letter-spacing: 0.8px;
    color: rgba(0, 0, 0, 0.45);
  }
}
</style>