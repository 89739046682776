<template>
  <div class="info">
    <div class="left">
      <a-avatar
        class="avatar"
        :size="72"
        :src="user.header"
        v-if="user.header"
      />
      <div v-else class="avatar">
        <default-avatar
          :name="user.userName"
          :fromHead="user.name === 'admin'"
          :size="72"
        />
      </div>

      <div>
        <Welcome :userName="user.userName" />
        <div class="left user-detail">
          <a-icon style="margin-right: 4px" type="mobile" />
          <span style="margin-right: 12px">{{ user.mobile }}</span>
          <a-icon style="margin-right: 4px" type="mail" />
          <span>
            {{ user.email }}
          </span>
        </div>
        <div style="margin-left: -12px; margin-top: 8px">
          <Tags :detail="detail" />
        </div>
      </div>
    </div>
    <div class="footer" v-if="detail.position">
      <div class="position">
        <DataDictFinder dictType="talentMap.talentMap_post" :dictValue="detail.position" />
      </div>
      <div>{{ detail.dept }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Welcome from "./welcome";
import DefaultAvatar from "@/components/default-avatar";
import Tags from "@/views/human-resources/employee/components/tags.vue";
import request from "@/api/request";
export default {
  components: {
    Welcome,
    DefaultAvatar,
    Tags,
  },
  data() {
    return {
      detail: {},
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  mounted() {
    request({
      url: "/user-service/talentMap/queryHomeEmployee/" + this.user.uuid,
    }).then((res) => {
      if (res) {
        this.detail = res;
      }
    });
  },
};
</script>

<style lang="less" scoped>
.info {
  background-image: url(https://s.upapi.cn/2022/07/20/640d51b63af768a167a10d431b49272f/user-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
  padding: 12px;
  .avatar {
    margin-right: 12px;
  }

  .user-detail {
    color: rgba(0, 0, 0, 0.45);
    line-height: 22px;
  }
  .extra {
    margin-left: auto;
    .statistic {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .label {
        margin-bottom: 4px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
      }
      .value {
        color: rgba(0, 0, 0, 0.85);
        font-size: 24px;
      }
    }
  }
}

.footer {
  border-top: 1px solid #d0d0d0;
  margin-top: 12px;
  padding-top: 12px;

  .position {
    color: #1890ff;
    margin-bottom: 4px;
    font-size: 500;
  }
}
</style>