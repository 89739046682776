<template>
  <div class="card">
    <div class="between header">
      <div class="title">{{ title }}</div>
      <div class="left extra" v-if="url" @click="$router.push(url)">
        <span>{{ name }}</span>
        <a-icon type="right" style="font-size: 12px; margin-top: 1px" />
      </div>
    </div>
    <div class="body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    url: {
      type: String,
    },
    name: {
      type: String,
      default: "更多",
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  background-color: #fff;
  .header {
    padding: 8px 8px 0 8px;

    .title {
      font-weight: bold;
      font-size: 14px;
    }
    .extra {
      color: #999;
      cursor: pointer;
    }
  }
  .body {
    padding: 8px;
  }
}
</style>