<template>
  <div class="container">
    <div>
      <div class="grid">
        <Statistic />
        <Notice />
      </div>
      <News />
    </div>

    <div>
      <UserInfo />
      <ApprovalCenter />
      <div style="flex: 1; background-color: #fff">
        <Links />
      </div>
      <Website />
    </div>
  </div>
</template>

<script>
import Statistic from "./components/statistic.vue";
import Notice from "./components/notice.vue";
import UserInfo from "./components/user-info.vue";
import ApprovalCenter from "./components/approval-center.vue";
import Links from "./components/links.vue";
import News from "./components/news.vue";
import Website from './components/website.vue'

export default {
  components: {
    Statistic,
    Notice,
    UserInfo,
    ApprovalCenter,
    Links,
    News,
    Website,
  },
};
</script>

<style lang="less" scoped>
.container {
  margin-top: -48px;
  display: flex;
  gap: 8px;

  &>div:first-child {
    flex: 1;

    .grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
      margin-bottom: 8px;
    }
  }

  &>div:last-child {
    width: 440px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
</style>