<template>
  <div
    class="default-avatar"
    :style="{
      height: size + 'px',
      width: size + 'px',
      'line-height': size + 'px',
    }"
  >
    <div class="value" :style="{ 'font-size': size * 0.4 + 'px' }">
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    // 从头部截取
    fromHead: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 32,
    },
  },

  computed: {
    value() {
      if (this.fromHead) {
        return this.name.length > 2 ? this.name.substring(0, 2) : this.name;
      } else {
        return this.name.length > 2
          ? [...this.name].reverse().slice(0, 2).reverse().join("")
          : this.name;
      }
    },
  },
};
</script>

<style scoped>
.default-avatar {
  border-radius: 50%;
  text-align: center;
  background-color: #1890ff;
  color: #fff;
}
.value {
  transform: scale(0.9);
}
</style>