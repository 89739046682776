<template>
  <Card title="快捷功能">
    <a-row>
      <a-col :span="6" v-for="item in filteredList" :key="item.id">
        <div class="item" @click="route(item.path)">
          <img :src="item.imagePath" alt="系统图标" />
          <div>{{ item.title }}</div>
        </div>
      </a-col>

      <a-col :span="6">
        <div class="item" @click="route('/chatgpt')">
          <img src="/openai.png" alt="系统图标" />
          <div>{{ "GPT 3.5 Turbo" }}</div>
        </div>
      </a-col>
    </a-row>
  </Card>
</template>

<script>
import Card from "./card.vue";
export default {
  components: {
    Card,
  },
  data() {
    return {
      list: [
        {
          title: "会议申请",
          imagePath: require("@/assets/icon11.png"),
          path: "/oa/meeting/add",
        },

        {
          title: "开票申请",
          imagePath: require("@/assets/icon12.png"),
          path: "/finance/invoice/apply",
        },

        {
          title: "酒水申领",
          imagePath: require("@/assets/icon14.png"),
          path: "/oa/material/apply",
        },

        {
          title: "我的考勤",
          imagePath: require("@/assets/icon5.png"),
          path: "/human-resources/clock",
        },

        {
          title: "通讯录",
          imagePath:
            "https://s.upapi.cn/2022/07/21/025126e503e208b05bb71e07e7528d90/employee.png",
          path: "/address-list",
        },

        {
          title: "群众来信公示",
          imagePath: require("@/assets/icon4.png"),
          path: "/party/letter",
        },

        {
          title: "部门协作",
          imagePath: require("@/assets/icon12.png"),
          path: "/collaborate",
        },
      ],
    };
  },

  computed: {
    filteredList() {
      return this.list.filter(
        (item) => (item.path && this.$getPermission(item.path)) || !item.path
      );
    },
  },

  methods: {
    route(path) {
      if (path) {
        this.$router.push(path);
      } else {
        this.$message.error("开发中，敬请期待！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  background-color: #fff;
  padding-bottom: 0;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 4px;
  color: #333;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    background-color: #f9f9f9;
  }

  img {
    height: 38px;
    margin-bottom: 6px;
  }
}
</style>