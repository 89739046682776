<template>
  <div class="news">
    <div class="left tab-bar">
      <div class="left tab" @click="setTab(1)" :class="activeTab === 1 ? 'active' : ''">
        <a-icon type="file-text" style="margin-right: 4px" />
        <span>公司新闻</span>
      </div>
      <div class="tab" @click="setTab(2)" :class="activeTab === 2 ? 'active' : ''">
        <a-icon type="star" style="margin-right: 4px" />
        <span>党群公告</span>
      </div>
      <div class="tab" @click="setTab(3)" :class="activeTab === 3 ? 'active' : ''">
        <a-icon type="star" style="margin-right: 4px" />
        <span>廉洁合规</span>
      </div>
      <div class="tab" @click="setTab(4)" :class="activeTab === 4 ? 'active' : ''">
        <a-icon type="star" style="margin-right: 4px" />
        <span>员工天地</span>
      </div>
      <div class="tab" @click="setTab(5)" :class="activeTab === 5 ? 'active' : ''">
        <a-icon type="star" style="margin-right: 4px" />
        <span>质量安全</span>
      </div>
    </div>

    <div class="content" v-if="activeTab === 1">
      <div class="left sub-tab-bar">
        <div
          class="tab"
          @click="setCategory('最新资讯')"
          :class="activeCategory === '最新资讯' ? 'active' : ''"
        >最新资讯</div>
        <div
          class="tab"
          v-for="item in categoryList"
          :key="item.value"
          @click="setCategory(item.value)"
          :class="activeCategory === item.value ? 'active' : ''"
        >{{ item.name }}</div>
        <div class="extra" @click="$router.push('/document')">
          <span>更多</span>
          <a-icon type="right" />
        </div>
      </div>
      <div class="list" v-if="list.length > 0">
        <div class="images">
          <div
            class="image"
            v-for="item in list.filter((item, index) => index < 3)"
            :key="item.id"
            @click="$router.push('/document/detail?id=' + item.id)"
          >
            <img :src="item.face" alt />
            <div class="footer">{{ item.title }}</div>
          </div>
        </div>

        <router-link
          class="left item"
          v-for="item in list.filter((item, index) => index > 2)"
          :key="item.id"
          :to="`/document/detail?id=${item.id}`"
        >
          <div class="name">{{ item.title }}</div>
          <div class="time">{{ $getDateString(item.createAt) }}</div>
        </router-link>
      </div>
      <div v-else style="padding-top: 12px; padding-bottom: 12px">
        <a-empty />
      </div>
    </div>

    <div class="content" v-if="activeTab === 2">
      <div class="left sub-tab-bar">
        <div
          class="tab"
          @click="setCategoryParty('最新公告')"
          :class="activeCategoryParty === '最新公告' ? 'active' : ''"
        >最新公告</div>
        <div
          class="tab"
          v-for="item in partyWorkType"
          :key="item.value"
          @click="setCategoryParty(item.value)"
          :class="activeCategoryParty === item.value ? 'active' : ''"
        >{{ item.name }}</div>
        <div class="extra" @click="$router.push('/party/public/notice')">
          <span>更多</span>
          <a-icon type="right" />
        </div>
      </div>
      <div class="list" v-if="notices.length > 0">
        <router-link
          class="left item"
          v-for="item in notices"
          :key="item.id"
          :to="`/party/public/notice/detail?id=${item.id}`"
        >
          <div class="name">{{ item.title }}</div>
          <div class="time">{{ $getDateString(item.publishAt) }}</div>
        </router-link>
      </div>
      <div v-else style="padding-top: 12px; padding-bottom: 12px">
        <a-empty />
      </div>
    </div>

    <div class="content" v-if="activeTab === 3">
      <div class="left sub-tab-bar">
        <div
          class="tab"
          @click="setCategory2('最新公告')"
          :class="activeCategoryParty2 === '最新公告' ? 'active' : ''"
        >最新公告</div>
        <div
          class="tab"
          v-for="item in honestType"
          :key="item.value"
          @click="setCategory2(item.value)"
          :class="activeCategoryParty2 === item.value ? 'active' : ''"
        >{{ item.name }}</div>
        <div
          class="tab"
          @click="setCategory2('举报方式')"
          :class="activeCategoryParty2 === '举报方式' ? 'active' : ''"
        >举报方式</div>
        <div class="extra" @click="$router.push('/party/public/honest')">
          <span>更多</span>
          <a-icon type="right" />
        </div>
      </div>
      <div v-if="activeCategoryParty2 !== '举报方式'">
        <div class="list" v-if="honestList.length > 0">
          <router-link
            class="left item"
            v-for="item in honestList"
            :key="item.id"
            :to="`/party/public/honest/detail?id=${item.id}`"
          >
            <div class="name">{{ item.title }}</div>
            <div class="time">{{ $getDateString(item.publishAt) }}</div>
          </router-link>
        </div>
        <div v-else style="padding-top: 12px; padding-bottom: 12px">
          <a-empty />
        </div>
      </div>
      <div class="way" v-else>
        <div class="title">来访地址</div>
        <div class="value">同仁街31号5层510室党群与纪监审计部</div>
        <div class="title">来信邮编</div>
        <div class="value">210008</div>
        <div class="title">举报电话</div>
        <div class="value">（025）83283646</div>
        <div class="title">举报邮箱</div>
        <div class="value">njlzyjx@bewg.net.cn</div>
      </div>
    </div>

    <div class="content" v-if="activeTab === 4">
      <div class="left sub-tab-bar">
        <div
          class="tab"
          :class="'active'"
        >员工天地</div>
      </div>
      <div class="list" v-if="staffCultureList.length > 0">
        <router-link
          class="left item"
          v-for="item in staffCultureList"
          :key="item.id"
          :to="`/oa/staffCulture/detail?id=${item.id}`"
        >
          <div class="name">{{ item.title }}</div>
          <div class="time">{{ $getDateString(item.publishAt) }}</div>
        </router-link>
      </div>
    </div>

    <div class="content" v-if="activeTab === 5">
      <div class="left sub-tab-bar">
        <div
          class="tab"
          :class="'active'"
        >质量安全</div>
      </div>
      <div class="list" v-if="qualityList.length > 0">
        <router-link
          class="left item"
          v-for="item in qualityList"
          :key="item.id"
          :to="`/oa/quality/detail?id=${item.id}`"
        >
          <div class="name">{{ item.title }}</div>
          <div class="time">{{ $getDateString(item.publishAt) }}</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { fetchList } from "@/api/document";
import { fetchList as fetchNotice } from "@/api/party";
import { fetchList as fetchStaffCultureList} from "@/api/oa/staffCulture";
import { fetchList as fetchStaffQualityList} from "@/api/oa/quality";
export default {
  data() {
    return {
      activeTab: 1,
      activeCategory: "最新资讯",
      activeCategoryParty: "最新公告",
      activeCategoryParty2: "最新公告",
      list: [],
      notices: [],
      honestList: [],
      staffCultureList: [],
      qualityList: []
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    categoryList() {
      return this.findDataDict("documentType.newsCenter");
    },
    partyWorkType() {
      return this.findDataDict("partyWorkType");
    },
    honestType() {
      return this.findDataDict("honestType");
    }
  },

  watch: {
    activeTab() {
      this.getList();
    },
    activeCategory() {
      this.getList();
    },
    activeCategoryParty() {
      this.getList();
    },
    activeCategoryParty2() {
      this.getList();
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      if (this.activeTab !== 3) {
        this.activeTab++;
      } else {
        this.activeTab = 1;
      }
    }, 10000);
    this.$once("hook:beforeDestroy", () => {
      if (this.timer) {
        clearInterval(this.timer);
      }
    });

    this.getList();
  },
  methods: {
    getList() {
      if (this.activeTab === 1) {
        fetchList({
          pageNum: 1,
          pageSize: 10,
          category:
            this.activeCategory === "最新资讯" ? undefined : this.activeCategory
        }).then(res => {
          if (res && Array.isArray(res.list)) {
            this.list = res.list;
          }
        });
      } else if (this.activeTab === 2) {
        fetchNotice({
          pageNum: 1,
          pageSize: 10,
          type:
            this.activeCategoryParty === "最新公告"
              ? undefined
              : this.activeCategoryParty,
          category: "partywork"
        }).then(res => {
          if (res && Array.isArray(res.list)) {
            this.notices = res.list;
          }
        });
      } else if (this.activeTab === 3){
        fetchNotice({
          pageNum: 1,
          pageSize: 10,
          type:
            this.activeCategoryParty2 === "最新公告"
              ? undefined
              : this.activeCategoryParty2,
          category: "discipline"
        }).then(res => {
          if (res && Array.isArray(res.list)) {
            this.honestList = res.list;
          }
        });
      } else if (this.activeTab === 4){
        fetchStaffCultureList({
          pageNum: 1,
          pageSize: 10,
        }).then(res => {
          if (res && Array.isArray(res.list)) {
            this.staffCultureList = res.list;
          }
        });
      } else {
        fetchStaffQualityList({
          pageNum: 1,
          pageSize: 10,
        }).then(res => {
          if (res && Array.isArray(res.list)) {
            this.qualityList = res.list;
          }
        });
      }
    },

    setTab(value) {
      this.activeTab = value;
    },

    setCategory(value) {
      this.activeCategory = value;
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
    setCategoryParty(value) {
      this.activeCategoryParty = value;
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
    setCategory2(value) {
      this.activeCategoryParty2 = value;
      if (this.timer) {
        clearInterval(this.timer);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.news {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.tab-bar {
  font-size: 14px;

  .tab {
    padding: 8px 12px;
    cursor: pointer;
  }

  .active {
    color: #1890ff;

    background-color: #fff;
  }
}

.content {
  flex: 1;
  background-color: #fff;
}

.sub-tab-bar {
  padding: 0 12px;
  border-bottom: 1px solid #f0f0f0;

  .tab {
    padding: 12px 0;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    margin-right: 16px;
  }

  .active {
    border-bottom-color: #1890ff;
  }

  .extra {
    padding: 8px 0;
    margin-left: auto;
    color: #999;
    cursor: pointer;
  }
}

.list {
  padding: 12px;

  .images {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;

    .image {
      position: relative;
      border-radius: 8px;
      cursor: pointer;
      margin-bottom: 8px;

      img {
        width: 100%;
        height: 20vh;
        object-fit: cover;
      }

      .footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 0px 0px 5px 5px;
        color: #fff;
        padding: 4px 8px;
        font-weight: bold;
      }
    }
  }

  .item {
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;

    padding-left: 12px;
    position: relative;

    &::before {
      position: absolute;

      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background-color: #f02532;
    }

    &:hover {
      background-color: #f9f9f9;
    }

    .name {
      width: 45vw;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .time {
      font-family: sans-serif;
      letter-spacing: 0.8px;
      color: rgba(0, 0, 0, 0.45);
    }
  }
}

.way {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;

  .title {
    color: #10636f;
    margin-bottom: 8px;
    position: relative;
    font-size: 16px;
    font-weight: bold;

    &::before {
      content: "";
      position: absolute;
      top: 49%;
      right: 130%;
      height: 1px;
      width: 6em;
      background-color: orange;
    }

    &::after {
      content: "";
      position: absolute;
      top: 49%;
      left: 130%;
      height: 1px;
      width: 6em;
      background-color: orange;
    }
  }

  .value {
    font-size: #222;
    font-size: 14px;
    margin-bottom: 24px;
  }
}
</style>